<template>
  <path id="Tracé_564" data-name="Tracé 564" d="M3.749,47.03a4.756,4.756,0,0,1-2.1-.47A3.147,3.147,0,0,1,.293,45.35,2.122,2.122,0,0,1,0,44.28V2.75A2.279,2.279,0,0,1,.639,1.212a3.737,3.737,0,0,1,1.647-1A5.006,5.006,0,0,1,3.749,0h56.5a4.732,4.732,0,0,1,2.1.47A3.163,3.163,0,0,1,63.7,1.679,2.133,2.133,0,0,1,64,2.75V8.7c0,.433-.479.784-1.066.784s-1.069-.351-1.069-.784V2.75a.967.967,0,0,0-.273-.661,1.6,1.6,0,0,0-.714-.43,2.108,2.108,0,0,0-.627-.093H3.749a2.017,2.017,0,0,0-.9.2,1.346,1.346,0,0,0-.585.522.9.9,0,0,0-.129.46V44.28a.964.964,0,0,0,.277.661,1.6,1.6,0,0,0,.708.43,2.233,2.233,0,0,0,.631.094h56.5a2.044,2.044,0,0,0,.9-.2,1.359,1.359,0,0,0,.587-.521.906.906,0,0,0,.125-.461V13.919c0-.432.48-.783,1.069-.783S64,13.487,64,13.919V44.28a2.261,2.261,0,0,1-.641,1.537,3.7,3.7,0,0,1-1.649,1,4.943,4.943,0,0,1-1.46.216ZM22.5,37.344a17,17,0,1,1,9.483,2.9A17.028,17.028,0,0,1,22.5,37.344Zm1.2-26.359a14.856,14.856,0,1,0,8.287-2.532A14.872,14.872,0,0,0,23.7,10.984Zm2.849,22.662a2.379,2.379,0,0,1-1.091-2.007V16.45a2.449,2.449,0,0,1,.112-.735,2.286,2.286,0,0,1,.319-.639,2.389,2.389,0,0,1,3.159-.7L42.2,21.97a2.363,2.363,0,0,1,.9.91,2.419,2.419,0,0,1,0,2.329,2.347,2.347,0,0,1-.9.908l-13.152,7.6a2.379,2.379,0,0,1-2.5-.066Zm1.161-17.413a.282.282,0,0,0-.1.111.261.261,0,0,0-.021.106V31.64a.326.326,0,0,0,.012.083.239.239,0,0,0,.032.062.266.266,0,0,0,.119.094.258.258,0,0,0,.1.02.241.241,0,0,0,.127-.036l13.154-7.6a.253.253,0,0,0,.1-.094.29.29,0,0,0,0-.259.254.254,0,0,0-.1-.094l-13.154-7.6a.261.261,0,0,0-.127-.037A.275.275,0,0,0,27.708,16.233Z" transform="translate(0.5 0.5)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</template>

<script>
export default {
name: "IconVideo"
}
</script>

<style scoped>

</style>